import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import BoardCrawlerCheck from "../views/pinterest/BoardCrawlerCheck";
import PinterestBase from "../views/pinterest/Base";
import TagList from "../views/tag/TagList";
import TagBase from "../views/tag/Base";
import TagDetail from "../views/tag/TagDetail";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    hidden: true,
  },
  {
    path: "/tag",
    component: TagBase,
    text: "Tag",
    icon: "mdi-tag",
    children: [
      {
        path: "match",
        name: "Tag.Match",
        component: TagList,
        text: "Tag Match",
      },
      {
        path: "create",
        name: "Tag.Create",
        component: TagDetail,
        text: "Tag Create",
      },
      {
        path: "detail/:tagKey",
        name: "Tag.Detail",
        component: TagDetail,
        hidden: true,
      },
    ],
  },
  {
    path: "/board",
    component: PinterestBase,
    text: "Pinterest",
    icon: "mdi-pinterest",
    children: [
      {
        path: "check",
        name: "Pinterest.Check",
        component: BoardCrawlerCheck,
        text: "Board Check",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
