<template lang="pug">
v-text-field(
  :label="label"
  v-model="commaValue"
  :rules="[nonEmptyMatchString]"
  :required="required"
)
</template>

<script>
export default {
  name: "CommaSeparateInput",
  props: {
    label: String,
    value: Array,
    required: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    nonEmptyMatchString(value) {
      return value.split(",").filter((v) => v.length === 0).length === 0;
    },
  },
  computed: {
    commaValue: {
      get() {
        return this.value.join(",");
      },
      set(value) {
        this.$emit("input", value.split(","));
      },
    },
  },
};
</script>

<style scoped></style>
