<template lang="pug">
v-app#app
  v-navigation-drawer(v-model="drawer" app)
    v-sheet.pa-4(color="lighten-4")
      v-avatar.mb-4(color="darken-1" size="64")
      div zhuny936772@gmail.com
    v-divider
    v-list
      v-list-group(
        :value="false"
        no-action
        :prepend-icon="route.icon"
        v-for="route in routes"
        v-if="!route.hidden"
      )
        template(v-slot:activator)
          v-list-item-content
            v-list-item-title(v-text="route.text")
        v-list-item(
          link
          v-for="subroute in route.children"
          v-if="!subroute.hidden"
          :link="true"
          :to="{name: subroute.name}"
        )
          v-list-item-content
            v-list-item-title(v-text="subroute.text")
  v-app-bar(app)
    v-app-bar-nav-icon(@click="drawer = !drawer")
    v-toolbar-title Zhunium
  v-main
    router-view
</template>

<script>
export default {
  name: "App",

  data: () => {
    return {
      drawer: false,
    };
  },
  computed: {
    routes() {
      return this.$router.options.routes;
    },
  },
};
</script>
