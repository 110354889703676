<template lang="pug">
router-view
</template>

<script>
export default {
  name: "Base",
};
</script>

<style scoped></style>
