<template lang="pug">
.app
  v-skeleton-loader(
    type="list-item-avatar-three-line, actions"
    class="mb-6"
    elevation="2"
    v-if="isLoading"
  )
  v-list(three-line v-else)
    BoardSmall(
      v-for="(board, index) in boardList"
      :key="index"
      :image-cover-url="board.imageCoverUrl"
      :name="board.name"
      :description="board.description"
      :expected-tags.sync="board.expectedTags"
      :status.sync="board.status"
    )
  v-card-actions
    v-spacer
    v-btn(
      dark right
      color="blue"
      :loading="isLoading"
      @click="loadBoard"
    )
      v-icon mdi-page-next
    v-btn(
      dark right
      color="blue"
      :loading="isSaving"
      @click="saveBoard"
    )
      v-icon mdi-content-save-check
    v-btn(
      dark right
      color="green"
      @click="autoSetBoard"
    )
      v-icon mdi-auto-fix
  .alert-wrap
    v-alert(
      v-for="alertInfo in alertInfoList"
      :key="alertInfo.id"
      :type="alertInfo.type"
      :value="alertInfo.visible"
      border="left"
      dense
      transition="scroll-y-reverse-transition"
    ) {{ alertInfo.message }}
</template>

<script>
import BoardSmall from "../../components/pinterest/BoardSmall";
import axios from "axios";
import url from "../../util/url";

export default {
  name: "BoardCrawlerCheck",
  components: { BoardSmall },
  data() {
    return {
      boardList: [],
      intervalId: null,
      startTime: null,
      interval: 1500, // in millisecond
      currentTime: null,
      paddingBoard: {},
      isSaving: false,
      isLoading: false,
      alertId: 2,
      alertInfoList: [],
    };
  },
  created() {
    this.loadBoard();
  },
  methods: {
    autoSetBoard() {
      const changedBoard = this.boardList
        .filter((board) => {
          return board.status === 0;
        })
        .map((board) => {
          if (board.expectedTags.length > 0) {
            board.expectedTags[0].selected = true;
            board.status = 1;
          } else {
            board.status = 2;
          }
        }).length;
      this.addAlert(true, `${changedBoard} Board(s)!`);
    },
    loadBoard() {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
      axios.get(url.pinterest.board.checkList).then(({ data: boardList }) => {
        this.boardList = boardList;
        this.isLoading = false;
        scrollTo(0, 0);
      });
    },
    saveBoard() {
      if (this.isSaving) {
        return;
      }
      this.isSaving = true;
      axios
        .post(url.pinterest.board.saveStatus, {
          boardList: this.boardList
            .filter((board) => {
              return board.status !== 0;
            })
            .map(({ key, status, tags, expectedTags }) => {
              return {
                key,
                status,
                tags: tags.concat(
                  expectedTags
                    .filter((tag) => {
                      return tag.selected;
                    })
                    .map((tag) => {
                      return tag.primaryName;
                    })
                ),
              };
            }),
        })
        .then(() => {
          this.addAlert(true, "Saved!");
          this.isSaving = false;
        })
        .catch(() => {
          this.addAlert(false, "Fail!");
          this.isSaving = false;
        });
    },
    addAlert(success, message) {
      const currentAlertId = this.alertId;
      this.alertInfoList.push({
        type: success ? "success" : "error",
        message,
        id: currentAlertId,
        visible: false,
      });
      this.alertId = currentAlertId + 1;
      this.registerAlertVisible(currentAlertId, true, 100);
      this.registerAlertVisible(currentAlertId, false, 1100);
    },
    registerAlertVisible(alertId, visible, after) {
      // 시간 지나면 visible을 수정시켜줘
      setTimeout(() => {
        this.alertInfoList.forEach((alertInfo) => {
          if (alertInfo.id === alertId) {
            alertInfo.visible = visible;
          }
        });
      }, after);
    },
  },
};
</script>

<style scoped>
.alert-wrap {
  position: fixed;
  left: 4px;
  bottom: 4px;
  width: 50%;
}
</style>
