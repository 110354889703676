<template lang="pug">
div
  v-list-item
    v-list-item-avatar(:width="64" :height="64")
      v-img(:src="imageCoverUrl")
    v-list-item-content
      v-list-item-title(v-text="name")
      v-list-item-subtitle(v-text="description")
  v-container.pt-0
    v-chip-group(
      column multiple
      active-class="primary--text"
    )
      v-chip(
        v-for="etag in expectedTags"
        :input-value="etag.selected"
        @input="setExpectedTag(etag, $event)"
        filter
      ) {{ etag.primaryName }}
    v-btn-toggle(value="status" @change="setStatus").d-flex.justify-end
      v-btn(:value="1" :color="darkColor('green', status, 1)" small)
        v-icon(dark small) mdi-calendar-check
      v-btn(:value="2" :color="darkColor('red', status, 2)" small)
        v-icon(dark small) mdi-close-circle-outline
      v-btn(:value="3" :color="darkColor('yellow', status, 3)" small)
        v-icon(dark small) mdi-help-rhombus
  v-divider
</template>

<script>
export default {
  name: "BoardSmall",
  props: {
    imageCoverUrl: String,
    name: String,
    description: String,
    expectedTags: Array,
    status: Number,
  },
  methods: {
    darkColor(color, v1, v2) {
      return v1 === v2 ? "blue-grey" : color;
    },
    setStatus(e) {
      // 선택을 안 했을 때에는 undefined를 준다.
      this.$emit("update:status", e ?? 0);
    },
    setExpectedTag(tag, v) {
      // 선택한 태그가 있으면 크롤링 하는 것
      tag.selected = v;
      const selectedTags = this.expectedTags.filter((t) => {
        return t.selected;
      });
      if (selectedTags.length > 0) {
        this.setStatus(1);
      } else {
        this.setStatus(0);
      }
      this.$emit("update:expectedTags", this.expectedTags);
    },
  },
};
</script>
