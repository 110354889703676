<template lang="pug">
.app
  v-container
    v-skeleton-loader(
      v-if="isLoading"
      type="article, actions"
      class="mb-6"
      elevation="2"
    )
    v-form(v-else)
      v-text-field(required v-model="primaryName" label="Name")
      CommaSeparateInput(required v-model="matchString" label="Match String")
      v-select(
        v-model="tagType"
        :items="tagTypeItems"
        item-text="text"
        item-value="value"
        label="Tag Type"
      )
      v-text-field(v-model="namuName" label="Namu Name")
      v-select(
        label="Field List"
        v-model="fieldsList"
        :items="fieldsListItems"
        multiple
        attach
      )
      v-card-actions
        v-btn(color="success" @click="save") Save
        v-btn(color="warning" @click="toList") List
</template>

<script>
import CommaSeparateInput from "../../components/common/CommaSeparateInput";
import url from "../../util/url";
import axios from "axios";
import tagType from "../../util/tag_type";

export default {
  name: "TagDetail",
  components: { CommaSeparateInput },
  data() {
    return {
      primaryName: "",
      matchString: [],
      tagType: "",
      namuName: "",
      tagTypeItems: tagType,
      fieldsList: [],
      fieldsListItems: [],
      isLoading: true,
    };
  },
  created() {
    Promise.all([
      new Promise((resolve) => {
        // load detail tag info
        if (!this.isDetail()) {
          resolve();
          return;
        }
        axios
          .get(
            url.tag.detail({
              tagKey: this.tagKey(),
            })
          )
          .then(
            ({
              data: { matchString, primaryName, tagType, namuName, fieldsList },
            }) => {
              this.primaryName = primaryName;
              this.matchString = matchString;
              this.tagType = tagType;
              this.namuName = namuName;
              this.fieldsList = fieldsList;
              resolve();
            }
          );
      }),
      new Promise((resolve) => {
        // load selectable tag list
        axios.get(url.tag.groupByTag).then(({ data }) => {
          this.fieldsListItems = data
            .map(({ tagType, items }) => {
              return [
                { header: tagType },
                items.map(({ primaryName, key }) => {
                  return {
                    text: primaryName,
                    value: key,
                  };
                }),
                { divider: true },
              ].flat();
            })
            .flat();
          resolve();
        });
      }),
    ]).then(() => {
      this.isLoading = false;
    });
  },
  methods: {
    save() {
      const tagData = {
        primaryName: this.primaryName,
        matchString: this.matchString,
        tagType: this.tagType,
        namuName: this.namuName,
        fieldsList: this.fieldsList,
      };
      const targetUrl = this.isDetail()
        ? url.tag.detail({
            tagKey: this.tagKey(),
          })
        : url.tag.create;
      axios.post(targetUrl, tagData).then(({ data: { key } }) => {
        // Go to detail page after saving
        if (!this.isDetail()) {
          this.$router.push({
            name: "Tag.Detail",
            params: {
              tagKey: key,
            },
          });
        }
      });
    },
    tagKey() {
      return this.$route.params.tagKey;
    },
    isDetail() {
      return this.tagKey() !== undefined;
    },
    toList() {
      this.$router.push({
        name: "Tag.Match",
      });
    },
  },
};
</script>

<style scoped></style>
