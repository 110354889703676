<template lang="pug">
.app
  v-container
    v-row
      v-col
        v-select(
          :items="tagType"
          label="Tag Type"
          outlined
          v-model="selectedTagType"
        )
    v-skeleton-loader(
      v-if="loading"
      type="article"
      elevation="2"
    )
    template(v-else)
      v-checkbox(v-model="isOnlyEmpty" :label="emptyLabel")
      template(v-for="tagList in tagGroupVisible")
        v-list(subheader :key="tagList.key")
          v-subheader(v-text="tagList.key")
          v-list-item(
            v-for="tag in tagList.subgroup"
            :key="tag.key"
          )
            v-list-item-content(class="align-start")
              v-list-item-title(v-text="")
                span(v-text="tag.primaryName")
                v-icon(
                  color="green"
                  v-if="tag.namuName.length > 0"
                ) mdi-sprout
            v-list-item-icon
              v-icon(
                color="orange"
                @click="goTagDetail(tag)"
              ) mdi-more
        v-divider
</template>

<script>
import axios from "axios";
import url from "../../util/url";
import tagType from "../../util/tag_type";
import CommaSeparateInput from "../../components/common/CommaSeparateInput";

export default {
  name: "TagList",
  components: { CommaSeparateInput },
  data() {
    return {
      tagGroupList: [],
      tagType,
      selectedTagType: "gg",
      loading: false,
      isOnlyEmpty: false,
    };
  },
  created() {
    this.loadTagList();
  },
  methods: {
    loadTagList() {
      this.loading = true;

      axios
        .get(
          url.tag.list({
            tagType: this.selectedTagType,
          })
        )
        .then(({ data }) => {
          this.tagGroupList = data;
          this.loading = false;
        });
    },
    goTagDetail(tag) {
      // go to detail tag page
      this.$router.push({
        name: "Tag.Detail",
        params: {
          tagKey: tag.key,
        },
      });
    },
  },
  watch: {
    selectedTagType() {
      // do something when changing tag type
      this.loadTagList();
    },
  },
  computed: {
    tagGroupVisible() {
      if (this.isOnlyEmpty) {
        return this.tagGroupListFiltered;
      } else {
        return this.tagGroupList;
      }
    },
    tagGroupListFiltered() {
      return this.tagGroupList
        .map(({ key, subgroup }) => {
          return {
            key,
            subgroup: subgroup.filter(({ namuName }) => {
              return namuName.length === 0;
            }),
          };
        })
        .filter(({ subgroup }) => {
          return subgroup.length > 0;
        });
    },
    emptyLabel() {
      const marked = this.tagGroupListFiltered.reduce((current, group) => {
        return current + group.subgroup.length;
      }, 0);
      const total = this.tagGroupList.reduce((current, group) => {
        return current + group.subgroup.length;
      }, 0);
      return `Only Empty (${marked}/${total})`;
    },
  },
};
</script>

<style scoped></style>
