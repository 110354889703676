const BASE_URL = process.env.VUE_APP_API_URL;

const _ = (p) => {
  return `${BASE_URL}${p}`;
};

export default {
  pinterest: {
    board: {
      checkList: _("/board"),
      saveStatus: _("/board/status-bulk"),
    },
  },
  tag: {
    list: ({ tagType }) => {
      return _(`/tag/type/${tagType}`);
    },
    groupByTag: _("/tag/group-by-tag"),
    saveMatchString: _("/tag/match-string"),
    create: _("/tag/create"),
    detail: ({ tagKey }) => {
      return _(`/tag/detail/${tagKey}`);
    },
  },
  whoami: "https://login.zhuny.co.kr/api/v1/whoami",
};
